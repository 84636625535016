/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";  
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import '~ngx-print-element/styles.css';

@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');




ion-header{
    background: #fff;
}




ion-toolbar{
    background: #fff;
    margin-top: 20px;
    min-height: 70px;
}

ion-menu-button {
    color: var(--ion-color-primary);
  }
  
  ion-header ion-toolbar{
    --background: transparent;
  }
  
  ion-content{
    --background: #fff;
    text-align: center;
  }
  svg{
    position: fixed;
    bottom: 0;
    right: 0;
    width: 100%;
    z-index: 0;
  }
  
  

ion-card-title, ion-card-subtitle{
    z-index: 1;
  }

.search_container {
    --background: transparent;
    border-radius: 30px;
    max-width: 280px;
    border: 1px solid var(--ion-color-primary);
    float: right;
    margin-right: 20px;
    color: var(--ion-color-primary);
    margin-top: 10px;
}



.search_icon {
    color: var(--ion-color-primary);
}

@media screen and (max-width: 400px){

  .search_container{
    max-width: 230px;
  }
}

@media screen and (max-width: 350px){

  .search_container{
    max-width: 190px;
  }
}
::-webkit-scrollbar{
    position: absolute;
    z-index: 5;
}

.icn{
    float: left;
    height: 50px;
    width: 50px;
    color: var(--ion-color-primary);
    margin-top: 3px;
    margin-left: 20px;
  }
  
.cd1 {
    
    background-color: #0093E9;
background-image: linear-gradient(160deg, #0093E9 0%, #80D0C7 100%);

    box-shadow: none;
    
}


.cd2{ 
    background: linear-gradient(349deg, rgba(213,213,255,1) 0%, rgba(255,166,44,1) 100%);
    box-shadow: none;
    
}

.list{
    background: #0093E9;   
}


.refresh_button {
    --background: transparent;
    border: 2px solid #fff;
    --box-shadow: none;
    color: #fff;
    border-radius: 10px;
    --padding-bottom: 10px;
    --padding-end: 10px;
    --padding-start: 10px;
    --padding-top: 10px;
    text-transform: none;
    margin-right: 15px;
}

.bd2{
    border: none;
}


ion-spinner{
    color: #fff;
}

.bg-color {
    background: #fff;
}

.bd1{
    border: .6px solid #0093E9;
}

// .bd3{
//     background: #58B852;
// }
.bd3{
    background-color: #0093E9;
    background-image: linear-gradient(160deg, #0093E9 0%, #80D0C7 100%);   
}
.placeholder_img {
    height: 160px;
    width: 180px;
    border-radius: 15px;
    border: .6px solid #0093E9;
    object-fit: cover;
}
.get_img {
    margin-top: 4px;
    width: 100%;
    object-fit: cover;
}

.get_img1 {
    width: 100px;
    object-fit: cover;
}

ion-toast.custom-toast {
    --background: #F4F4FA;
    --box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.2);
    --color: #4b4a50;
}

ion-toast.custom-toast::part(message) {
    font-family: 'poppins', sans-serif;
}
ion-toast.custom-toast::part(button) {
    border-left: 1px solid #d2d2d2;
    color: var(--ion-color-primary);
    font-size: 15px;
}
ion-toast.custom-toast::part(icon) {
    color: var(--ion-color-primary);
}

.pic_button {
    background-color: var(--ion-color-secondary);
    margin-left: auto;
    margin-right: auto;
    width: 50px;
    border-radius: 100%;
    height: 50px;
}

.my-modal-class {
    --min-width: 100vw;
    --max-width: 176vw;
    --height: 100%;

    .modal-wrapper {
        width: 90%;
        border-radius: 15px;
    }
}

@media (min-width:1px) and (max-width:550px) {
    .refresh {
        margin-top: 0px;
    }
}

@media (min-width:1px) and (max-width:768px) {
    .btn2{
        margin-top: 10px;
    }
}

@media (min-width:769px) {
    .btn2{
        margin-top: 20px;
    }
}
@media (min-width:1px) and (max-width:768px) {
    .text-luqman{
        margin-top: 10px;
    }
}
@media (min-width:769px) {
    .text-luqman{
        margin-top: 20px;
    }
}
.printbtn{
    padding: 14px;
    text-align: center;
    font-size: 18px;
    color: white;
    border-radius: 10px;
    height: 47px;
}
.item-2{
    --background: transparent;
}
//print page

.t2{
    z-index: 2;
}
ion-content {
    –offset-bottom: auto !important;
    –overflow: auto;
    background: var(–ion-toolbar-background, var(–ion-background-color, #f4f5f8)) !important;
    overflow: auto;
&::-webkit-scrollbar {
    width: 15px;
    height: 15px;
    }
    
    &::-webkit-scrollbar-track {
    background: #fff;
    }
    
    &::-webkit-scrollbar-track:hover {
    background: #f7f7f7;
    }
    
    &::-webkit-scrollbar-thumb {
    background: #ccc;
    }
    
    &::-webkit-scrollbar-thumb:hover {
    background: #94173a
    }
    
    .inner-scroll {
    scrollbar-width: thin;
    }
}